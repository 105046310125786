/* /////////////////////////////
 //// Responder
 /////////////////////////////// */

Aligent.Responder = Class.create({
    initialize: function() {
        this.actions = [];
        this.width = Aligent.window.getWidth();
        this.bindEvents();
    },

    bindEvents: function() {
        Event.observe(window, 'resize', this.onResize.bind(this));
    },

    onResize: function() {
        this.width = Aligent.window.getWidth();
        for (var i in this.actions) {
            this.testAction(this.actions[i]);
        }
    },

    testAction: function(action) {
        if (((action.min <= this.width || action.min === false)
            && (action.max >= this.width || action.max === false))
            && action.active !== true) {
            action.action();
        }
        if (((this.width > action.max && action.max !== false)
            || (this.width < action.min && action.min !== false))
            && action.active !== false) {
            action.unaction();
        }
    },

    addAction: function(action) {
        this.actions.push(action);
        this.testAction(action);
    }
});

Aligent.responder = new Aligent.Responder();