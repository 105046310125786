Aligent.DragScroll = Class.create({
    initialize: function (elements) {
        this.elements = elements;
        this.dragEl = null;
        this.clickedEl = null;
        this.dragStartX = 0;
        this.mouseStartX = 0;
        this.stopEvents = false;
        this.bindEvents()
    },

    bindEvents: function() {

        if (Aligent.SupportsTouch) {
            document.observe('touchmove', this._onMouseMove.bind(this));
            this.elements.invoke('addEventListener', 'touchstart', this._onMouseDown.bind(this));
        } else {
            document.observe('mousemove', this._onMouseMove.bind(this));
        }

        this.elements.invoke('observe', 'click', this._onClick.bind(this));
        this.elements.invoke('observe', 'mousedown', this._onMouseDown.bind(this));

        document.observe('mouseup', this._onMouseUp.bind(this));
        document.observe('touchend', this._onMouseUp.bind(this));
    },

    _onMouseMove: function(event) {
        if(this.dragEl) {
            this.stopEvents = true;
            var clientXPosition = (event.clientX === undefined) ? event.changedTouches[0].clientX : event.clientX;
            var mousePosition = clientXPosition - this.mouseStartX;

            //Limit to position negative numbers, and negative numbers that are not more than the difference
            //between the width of the element and the width of it's parent container
            var newElementPosition = (mousePosition + this.dragStartX);
            var containerWidthDifference = this.dragEl.parentNode.getWidth() - this.dragEl.getWidth();
            if(newElementPosition <= 0 && newElementPosition >= containerWidthDifference) {
                this.dragEl.style.left = (newElementPosition) + 'px';
            }
        }
    },

    _onClick: function(ev) {
        if(this.stopEvents) {
            ev.stop();
            this.stopEvents = false;
        }
    },

    _onMouseDown: function(ev) {
        ev.stop();

        if(ev.currentTarget.style.left !== '') {
            this.dragStartX = parseInt(ev.currentTarget.style.left.replace('px', ''));
        }

        this.mouseStartX = (ev.clientX === undefined) ? ev.changedTouches[0].clientX : ev.clientX;
        this.dragEl = ev.currentTarget;
        this.clickedEl = ev.target;
    },

    _onMouseUp: function(ev) {
        if(this.stopEvents) {
            ev.stop();
        }

        this.dragStartX = 0;
        this.mouseStartX = 0;
        this.dragEl = null;
        this.clickedEl = null;
    }

});