/* Responder Class Tools */

Aligent.ResponderClassAction = Class.create(Aligent.EventManager, {
    initialize: function(min, max, element, classes) {
        this._events = {};
        this.active = false;
        this.min = min;
        this.max = max;
        this.element = element;
        this.classes = classes;
    },

    action: function() {
        var _this = this;

        this.classes.each(function(item) {
            _this.element.addClassName(item);
        });

        this.active = true;
    },

    unaction: function() {
        var _this = this;

        this.classes.each(function(item) {
            _this.element.removeClassName(item);
        });

        this.active = false;
    }

});

Aligent.ResponderClassAction.ACTION = 'action';
Aligent.ResponderClassAction.UNACTION = 'unaction';