/* Form Disabler */

Aligent.ResponderFormDisablerAction = Class.create(Aligent.EventManager, {
    initialize: function(min, max, form) {
        this._events = {};
        this.active = false;
        this.min = min;
        this.max = max;
        this.form = form;
    },

    action: function() {
        this.form.select('select, input, textarea').each(function(el) {
            el.writeAttribute('disabled', 'disabled');
        });
        this.active = true;
    },

    unaction: function() {
        this.form.select('select, input, textarea').each(function(el) {
            el.writeAttribute('disabled', false);
        });
        this.active = false;
    }

});

Aligent.ResponderFormDisablerAction.ACTION = 'action';
Aligent.ResponderFormDisablerAction.UNACTION = 'unaction';