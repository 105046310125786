/* /////////////////////////////
 //// Accordian
 /////////////////////////////// */

Aligent.Accordian = Class.create({
    initialize: function(el, setHeights) {
        this.setHeights = setHeights !== null ? setHeights : true;
        this.accordian = el;
        this.dds = this.accordian.select('dd');
        this.dts = this.accordian.select('dt');
        this.heights = [];
        this.getHeights();
        this.bindEvents();
    },

    getHeights: function() {
        var _this = this;
        this.heights = [];
        this.dds.each(function(el) {
            el.setStyle({ height: null });
            _this.heights.push(el.getHeight());
            el.setStyle({ height: 0 });
        });
    },

    bindEvents: function() {
        this.dts.invoke('observe', 'click', this.dtClick.bind(this));
    },

    dtClick: function(ev) {
        var el = Event.element(ev),
            alreadyActive = el.hasClassName('active'),
            nextDd = el.next('dd'),
            index;

        this.dds.each(function(dd, i) {
            if (dd == nextDd) index = i;
        });

        this.dts.invoke('removeClassName', 'active');
        this.dds.invoke('removeClassName', 'active');
        this.dds.invoke('setStyle', {
            height: 0
        });

        if (!alreadyActive) {
            el.addClassName('active');
            nextDd.addClassName('active');
            if(this.setHeights) {
                nextDd.setStyle({
                    height: this.heights[index] + 'px'
                });
            } else {
                nextDd.setStyle({
                    height: null
                });
            }
        }
    }

});
