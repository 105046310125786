/* Sort */

Aligent.ResponderSortAction = Class.create(Aligent.EventManager, {
    initialize: function(min, max, items, column, columnHolder, columnInner, toColumns, fromColumns) {
        this._events = {};
        this.active = false;
        this.min = min;
        this.max = max;
        this.items = items;
        this.columnHolder = columnHolder;
        this.columnInner = columnInner;
        this.column = column;
        this.toColumns = toColumns;
        this.fromColumns = fromColumns;
        this.appenders = [];
        this.temp = column.clone().hide();
        $$('body').first().insert({ bottom: this.temp });

        this._sortElements();
    },

    _sortElements: function() {
        var attribute = 'data-orderid';
        this.items.sort(function(a, b) {
            return a.readAttribute(attribute) - b.readAttribute(attribute);
        });
    },

    _generateColumns: function(count) {
        var column, i, appender;
        this.appenders = [];
        this.columnHolder.update('');
        for (i = 0 ; i < count ; i ++) {
            column = this.column.clone();
            this.columnHolder.insert({ bottom: column });

            if (this.columnInner != false) {
                appender = this.columnInner.clone();
                column.insert({ bottom: appender });
            } else {
                appender = column;
            }

            this.appenders.push(appender);
        }
    },

    _distribute: function()
    {
        var appenderCount = this.appenders.length, appenderNumber = 0, that = this;

        this.items.each(function(item) {
            that.appenders[appenderNumber].insert({ bottom: item });
            appenderNumber ++;
            if (appenderNumber >= appenderCount) appenderNumber = 0;
        });
    },

    _shiftTemp: function()
    {
        var that = this;
        this.items.each(function(item) {
            that.temp.insert({ bottom: item });
        });
    },

    action: function() {
        this._shiftTemp();
        this._generateColumns(this.toColumns);
        this._distribute();
        this.active = true;
    },

    unaction: function() {
        this._shiftTemp();
        this._generateColumns(this.fromColumns);
        this._distribute();
        this.active = false;
    }

});

Aligent.ResponderSortAction.ACTION = 'action';
Aligent.ResponderSortAction.UNACTION = 'unaction';