Aligent.SliderDragScroll = Class.create(Aligent.DragScroll, {
    initialize: function ($super, elements, slider) {
        $super(elements)

        this._slider = slider;
    },

    _onMouseMove: function(event) {
        // No need to track mouseMove
    },

    _onMouseUp: function($super, event) {
        var mousePosition = (event.clientX === undefined) ? event.changedTouches[0].clientX - this.mouseStartX : event.clientX - this.mouseStartX;

        $super(event);

        if (mousePosition < -10) {
            this._slider.next();
        } else if (mousePosition > 10) {
            this._slider.previous();
        }

    }
});