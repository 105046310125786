/* /////////////////////////////
 //// Navigation Select
 /////////////////////////////// */

Aligent.NavigationSelect = Class.create({
    initialize: function(selector) {
        this.selector = selector;
        this.selected = $$(selector);
        this.bindEvents();
    },

    bindEvents: function() {
        this.selected.invoke('observe', 'change', this.onChange.bind(this));
    },

    onChange: function(ev) {
        var select = $(Event.element(ev)),
            option = select.select('option'),
            url;
        if (option.length) {
            option = option[select.selectedIndex];
            url = option.readAttribute('data-url');
            window.location = url;
        }
    }
});