/* Responder Move */

Aligent.ResponderMoveAction = Class.create(Aligent.EventManager, {
    initialize: function(min, max, element, to, toPosition, from, fromPosition) {
        this._events = {};
        this.active = false;
        this.min = min;
        this.max = max;
        this.element = element;
        this.to = to;
        this.toPosition = toPosition;
        this.from = from;
        this.fromPosition = fromPosition;
    },

    action: function() {
        var position = {};
        position[this.toPosition] = this.element;
        this.active = true;
        this.to.insert(position);
        this.dispatch(Aligent.ResponderMoveAction.ACTION);
    },

    unaction: function() {
        var position = {};
        position[this.fromPosition] = this.element;
        this.active = false;
        this.from.insert(position);
        this.dispatch(Aligent.ResponderMoveAction.UNACTION);
    }

});

Aligent.ResponderMoveAction.ACTION = 'action';
Aligent.ResponderMoveAction.UNACTION = 'unaction';