/* /////////////////////////////
 //// Slider
 /////////////////////////////// */

Aligent.Slider = Class.create(Aligent.EventManager, {
    initialize: function(slider, config) {
        this._config = config;
        this._handleConfig();

        this._events = {};
        this._selector = slider;
        this._el = $$(slider).first();
        this._frameEls = this._el.select(this._config.frameSelector)
        this._frameCount = this._frameEls.length + this._config.frameCountAdjustment;
        this._delayTimer = null;
        this._transitionTimer = null;
        this._current = 0;
        this._isRunning = false;
        this._isPaused = false;

        this._frameEls.first().addClassName(this._config.activeClass);

        this._setTransition();
        this._bindEvents();

        if (typeof(this._config.delayStart) !== 'undefined') {
            this.start.bind(this).delay(this._config.delayStart/1000);
        } else {
            this.start();
        }
    },

    _bindEvents: function() {
        if (this._config.pauseOnHover) {
            this._el
                .observe('mouseenter', this._onMouseEnter.bind(this))
                .observe('mouseleave', this._onMouseLeave.bind(this));
        }

        this._el.select(this._config.frameSelector).invoke('observe', 'click', this._onClick.bind(this));
    },

    _onMouseEnter: function() {
        this.pause();
    },

    _onMouseLeave: function() {
        this.resume();
    },

    _onClick: function(ev) {
        var el = Event.findElement(ev, this._config.frameSelector),
            frames = this._el.select(this._config.frameSelector),
            index = frames.indexOf(el);

        this.dispatch(Aligent.Slider.FRAME_CLICKED, {index: index});
    },

    _handleConfig: function() {
        this._config.transitionTime = this._config.transitionTime || 0;
        this._config.delayTime = this._config.delayTime || 0;
        this._config.shiftValue = this._config.shiftValue || 100;
        this._config.shiftType = this._config.shiftType|| '%';
        this._config.pauseOnHover = this._config.pauseOnHover == false ? this._config.pauseOnHover : true;
        this._config.ease = this._config.ease || 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
        this._config.frameSelector = this._config.frameSelector || '.frame';
        this._config.frameCountAdjustment = this._config.frameCountAdjustment || 0;
        this._config.activeClass = this._config.activeClass || 'active';
    },

    _delayStart: function() {
        if (this._isPaused || !this._config.delayTime) {
            return false;
        }

        var that = this;
        this._delayTimer = setTimeout(function() {
            that._delayEnd();
        }, this._config.delayTime);

        this.dispatch(Aligent.Slider.DELAY_STARTED);
    },

    _delayEnd: function() {
        this.dispatch(Aligent.Slider.DELAY_ENDED);
        if (!this._isPaused) this.next();
    },

    _setTransition: function() {
        var ease = this._config.ease,
            value = 'left ' + this._config.transitionTime + 'ms ' + ease,
            prefixes = [ '-webkit-', '-moz-', '-o-' ],
            css = {};

        prefixes.each(function(prefix) {
            css[prefix + 'transition'] = value;
            css[prefix + 'transition-timing-function'] = ease;
            css[prefix + 'transform'] = 'translateZ(0)';
        });

        this._el.setStyle(css);
    },

    _transitionStart: function(index) {
        if (index >= this._frameCount || index < 0) {
            return false;
        }

        var that = this;

        clearTimeout(this._delayTimer);
        this._delayTimer = null;
        clearTimeout(this._transitionTimer);
        this._transitionTimer = null;

        this._el.setStyle({ left: '-' + (index * this._config.shiftValue) + this._config.shiftType });
        this._current = index;

        this._frameEls.invoke('removeClassName', this._config.activeClass);
        this._frameEls[index].addClassName(this._config.activeClass);

        this.dispatch(Aligent.Slider.TRANSITION_STARTED);

        this._transitionTimer = setTimeout(function() {
            that._transitionEnd();
        }, this._config.transitionTime);
    },

    _transitionEnd: function() {
        clearTimeout(this._transitionTimer);
        this._transitionTimer = null;
        this.dispatch(Aligent.Slider.TRANSITION_ENDED);
        this._delayStart();
    },

    next: function() {
        this.transitionTo(this._current + 1 >= this._frameCount ? 0 : this._current + 1);
    },

    previous: function() {
        this.transitionTo(this._current - 1 < 0 ? this._frameCount - 1 : this._current - 1);
    },

    transitionTo: function(index) {
        this._transitionStart(index);
    },

    start: function() {
        this._delayStart();
        this._isRunning = true;
        this.dispatch(Aligent.Slider.STARTED);
    },

    isRunning: function() {
        return this._isRunning;
    },

    getCurrent: function() {
        return this._current;
    },

    stop: function() {
        clearTimeout(this._delayTimer);
        this._delayTimer = null;
        this._isRunning = false;
        this.dispatch(Aligent.Slider.STOPPED);
    },

    pause: function() {
        this._isPaused = true;
    },

    resume: function() {
        this._isPaused = false;
        if (this._isRunning) this._delayStart();
    },

    isPaused: function() {
        return this._isPaused;
    }

});

Aligent.Slider.STARTED = 'start';
Aligent.Slider.STOPED = 'stop';
Aligent.Slider.TRANSITION_STARTED = 'transitionStart';
Aligent.Slider.TRANSITION_ENDED = 'transitionEnd';
Aligent.Slider.DELAY_STARTED = 'delayStart';
Aligent.Slider.DELAY_ENDED = 'delayEnd';
Aligent.Slider.FRAME_CLICKED = 'frameClicked';