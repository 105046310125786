/* Discover */

Aligent.ResponderDiscoverAction = Class.create(Aligent.EventManager, {
    initialize: function(min, max, discover, columnsAction) {
        this._events = {};
        this.active = false;
        this.min = min;
        this.max = max;
        this.discover = discover;
        this.columnsAction = columnsAction;
    },

    action: function() {
        this.discover.sort(this.columnsAction);
        this.discover.render(this.columnsAction);

        this.active = true;
    },

    unaction: function() {
        this.active = false;
    }

});

Aligent.ResponderDiscoverAction.ACTION = 'action';
Aligent.ResponderDiscoverAction.UNACTION = 'unaction';