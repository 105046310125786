/* /////////////////////////////
 //// Notice Manager
 /////////////////////////////// */

/* Handles notice queuing */

Aligent.NoticeManager = Class.create({

    initialize: function(el) {
        this._el = el;
        this._messages = [];
        this._currentMessage = null;
        this._completedMessages = [];
        this._isRunning = false;
    },

    add: function(notice) {
        if (Object.prototype.toString.call(notice) === '[object Array]') {
            for (var i in notice) {
                if (notice.hasOwnProperty(i)) {
                    this.add(notice[i]);
                }
            }

            return;
        }

        this._messages.push(notice);

        if (!this._isRunning) {
            this.run();
        }
    },

    run: function() {
        if (this._isRunning) return;
        this._isRunning = true;
        this._startMessage();
    },

    _startMessage: function() {
        if (!this._messages.length) {
            this._isRunning = false;
            this._el.setStyle({ display: 'none' });
            return;
        }

        this._el.setStyle({ display: 'block' });

        this._currentMessage = this._messages.shift();
        this._currentMessage.listen(Aligent.Notice.FINISHED, this._messageComplete.bind(this));
        this._currentMessage.show.bind(this._currentMessage).delay(0.01);
    },

    _messageComplete: function() {
        this._completedMessages.push(this._currentMessage);
        this._currentMessage = null;
        this._startMessage();
    }

});
