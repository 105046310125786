/* /////////////////////////////
 //// MinHeight
 /////////////////////////////// */

/* Adjusts the height of all the el's passed to be the height of the tallest el */

Aligent.MinHeight = Class.create({
    initialize: function(els) {
        this.els = els;

        if (this.els) {
            this._bindEvents();
            this.update();
        }
    },

    _bindEvents: function() {
        Event.observe(window, 'resize', this._onResize.bind(this));
        Event.observe(window, 'load', this.update.bind(this));
    },

    _onResize: function() {
        this.update();
    },

    update: function() {
        var height = 0;

        this.els.each(function(el) {
            var elHeight;
            el.setStyle({ height: 'auto' });
            elHeight = el.getHeight();
            if (elHeight > height) {
                height = elHeight;
            }
        });

        this.els.each(function(el) {
            el.setStyle({ height: height + 'px' });
        });
    }

});