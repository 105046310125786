/* /////////////////////////////
 //// Modal
 /////////////////////////////// */

Aligent.Modal = Class.create(Aligent.EventManager, {
    initialize: function(outer, modal, cssAnimationTime) {
        this._events = {};
        this.el = $$(outer).first().hide();
        this.cssAnimationTime = cssAnimationTime;
        this.modal = this.el.select(modal).first();
        this.el.show();
        this.testWindow();
        this.el.hide();
        this.bindEvents();
        this.isOpen = false;
    },

    bindEvents: function() {
        this.windowResizeHandler = this.windowResize.bind(this);
        this.el.observe('click', this.close.bind(this));
        this.el.select('.close').invoke('observe', 'click', this.close.bind(this));
        this.el.select('.no-click-through').invoke('observe', 'click', this.noClick.bind(this));
        document.observe('keydown', this.keyPress.bind(this));
    },

    windowResize: function(ev) {
        this.testWindow();
    },

    noClick: function(ev) {
        if (ev.stopPropagation)    ev.stopPropagation();
        if (ev.cancelBubble!=null) ev.cancelBubble = true;
    },

    open: function() {
        this.isOpen = true;
        this.el.show();
        Element.addClassName.delay(0.01, this.el, 'active');
        this.testWindow.bind(this).delay(0.02);
        Event.observe(window, 'resize', this.windowResizeHandler);
        this.dispatch(Aligent.Modal.OPENED);
    },

    close: function() {
        this.isOpen = false;
        this.el.removeClassName('active');
        Element.hide.delay(this.cssAnimationTime, this.el);
        this.dispatch.bind(this).delay(this.cssAnimationTime, Aligent.Modal.CLOSED);
        Event.stopObserving(window, 'resize', this.windowResizeHandler);
    },
    
    testWindow: function() {
        if (!this.isOpen) {
            this.el.show();
        }
        var windowHeight = window.document.documentElement.clientHeight,
            elHeight = this.modal.getLayout().get('border-box-height') + this.modal.viewportOffset().top;

        if (!this.isOpen) {
            this.el.hide();
        }

        if (windowHeight < elHeight) {
            this.el.addClassName('small');
        } else {
            this.el.removeClassName('small');
        }
    },

    keyPress: function(ev)
    {
        var escKey = 27;

        if (this.isOpen) {
            if(ev.keyCode === escKey) this.close();
           }
    }

});

Aligent.Modal.CLOSED = 'modalClosed';
Aligent.Modal.OPENED = 'modalOpened';