/* /////////////////////////////
 //// Event Manager
 /////////////////////////////// */

Aligent.EventManager = Class.create({

    listen: function(event, callback, el) {
        if (this._events[event]) {
            this._events[event].push({ callback: callback, el: el });
        } else {
            this._events[event] = [{ callback: callback, el: el }];
        }
        return this;
    },

    stopListening: function(event, callback) {
        if (!this._events[event]) return false;
        if (typeof callback == 'undefined') {
            this._events[event] = null;
        } else {
            var i = 0, index = -1;
            for (var item in this._events[event]) {
                if (this._events[event].callback == callback) {
                    index = i;
                }
                i ++;
            }

            if (index != -1) {
                this._events[event].splice(index, 1);
            }
        }
        return this;
    },

    dispatch: function(event, memo) {
        var i = 0, that = this;

        if (!this._events[event]) return false;

        for (i; i < this._events[event].length; i++) {
            this._events[event][i].callback.apply(that._events[event][i].el, [ that, memo ]);
        }

        return this;
    }
});