/* Direction Control */

Aligent.SliderDirectionControl = Class.create({
    initialize: function(slider, previousControlSelector, nextControlSelector) {
        this.slider = slider;
        this.previousControl = $$(previousControlSelector) || null;
        this.nextControl = $$(nextControlSelector) || null;
        this.bindEvents();
    },

    bindEvents: function() {
        this.nextControl.invoke('observe', 'click', this.slider.next.bind(this.slider));
        this.previousControl.invoke('observe', 'click', this.slider.previous.bind(this.slider));
    }
});