/* Page Control */

Aligent.SliderPageControl = Class.create({
    initialize: function(slider, pageControlSelector) {
        this.slider = slider;
        this.pageControl = $$(pageControlSelector).first() || null;
        if (this.pageControl) {
            this.bindEvents();
        }

        this.updateCurrent();
    },

    bindEvents: function() {
        var that = this;
        this.pageControl.select('.item').invoke('observe', 'click', that.pageClick.bind(this));
        this.slider.listen(Aligent.Slider.TRANSITION_STARTED, this.updateCurrent, this);
    },

    pageClick: function(ev) {
        var el = Event.element(ev),
            pages = el.up().select('.item'),
            index = pages.indexOf(el);
        this.slider.transitionTo(index);
    },

    updateCurrent: function(ev) {
        this.pageControl.select('.item').invoke('removeClassName', 'active');
        this.pageControl.select('.item')[this.slider.getCurrent()].addClassName('active');
    }
});